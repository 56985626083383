/*@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */ /*
  body {
    padding-top: 50px;
  }
}*/

/* removing bootstrap default capitalization */
h1, h2, h3, h4, h5, h6 {
  text-transform: unset !important;
}

.text-large {
  font-size: 150%;
}

.product-save-row {
  background-color: #cbcbcb;
  height: 70px;
}

.product-save-row-fixed {
  position: fixed;
  z-index: 999999999;
  bottom: 0;
}

.product-thumbnail {
    max-height: 400px;
    width: auto;
    margin: 0 auto;
}

.product-card {
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
    transition: all .2s ease-in-out;
}

.product-card-body {
    text-align: center;
}

.image-div {
    height: 400px;
}

.image-div img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.product-card.card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.28);
}

.product-card-body .col button {
    height: 100%;
    width: 100%;
}

.product-card-body .col {
    padding-left: 5px;
    padding-right: 5px;
}

.product-card-body {
    padding: 10px 20px;
}

.product-card.border-light {
    border: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.textfield {
  width: 55px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196F3;
  -webkit-transition: .4s;
  transition: .4s;
}

.textfield-slider {
  color: white;
  font-size: smaller;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #828b92;
  transition: .4s;
  border-radius: 10px;
}

.textfield-slider::before {
  position: absolute;
  content: "";
  height: 12px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 8px;
}

input:checked + .textfield-slider {
  background-color: #f0ad4e;
}

input:focus + .textfield-slider {
  box-shadow: 0 0 1px #f0ad4e;
}

input:checked + .textfield-slider:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
}

.slider-variant {
  background-color: #f0ad4e;
}

.slider-variant-unassociation {
  background-color: #d9534f;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #f0ad4e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f0ad4e;
}

input:checked + .slider.slider-variant {
  background-color: #4bbf73;
}

input:focus + .slider.slider-variant {
  box-shadow: 0 0 1px #4bbf73;
}

input:checked + .slider.slider-variant-unassociation {
  background-color: #4bbf73;
}

input:focus + .slider.slider-variant-unassociation {
  box-shadow: 0 0 1px #4bbf73;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.input-close-icon {
  text-align: center;
  color: #c3c3c3;
  background-color: transparent;
  position: relative;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  font-size: 20px;
  transition: all .2s ease-in-out;
  top: -72px;
  right: -190px;
  padding: 0px 5px 0 7px;
  border-radius: 10%;
}

.date-input {
  top: -37px;
  right: 5px;
  float: right;
}

.input-with-clear {
  position: relative;
}

.input-with-clear .form-group:hover ~ .input-close-icon {
  opacity: 1;
}

.input-close-icon:hover {
  opacity: 1;
  background-color: #eaeaea;
  font-weight: bold;
  color: #888;
}

.download-product-btn {
  color: red;
  cursor: pointer;
}

.clickable-text {
  color: black;
}

.clickable-text:hover {
  cursor: pointer;
  text-decoration: underline;
  color: black;
}

.variant-header-image {
  display: inline-block;
  padding: 0 20px 0 0;
  height: 30px;
}

.variant-header-image img {
  object-fit: contain;
  width: 50px;
  height: 100%;
}

.variant-pricing tr {
  background: #f7f7f7;
}

.variant-pricing th td{
  width: 120px;
}

.variant-pricing input {
  background: "white";
  border: "none";
  width: 70px;
}

.variant-pricing-table {
  padding: 0;
}

@media only screen and (max-width: 1200px) {
  .variant-pricing-table {
    overflow-x: scroll;
  }
}

.product-channel-variant-row {
  border-style: solid;
  border-width: 1px;
  border-color: #c3c3c3;
}

.product-channel-variant-row:hover {
  background: #f7f7f7;
}

.dropdown-menu-item:hover {
  background: #4bbf73;
}

.dropdown-menu-unassociation:hover {
  background: #f7f7f7
}

.datepicker-wrapper {
  display: block !important;
}

.alert-box-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  padding: 3px;
  animation-name: alert-box-wrapper-animation;
  animation-duration: 0.5s;
}

.navbar-dropdown-submenu {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1a1a1a;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

@keyframes alert-box-wrapper-animation {
  from {top: -35px;}
  to {top: 0;}
}

.default-image-border {
  border: dotted;
  border-width: 1px;
  border-color: red;
}